import { FC, ReactNode } from 'react';
import { isEmpty } from 'lodash';

import { MediaQuery, MediaQueryDirection, useResponsive } from '@web/hooks/useResponsive/useResponsive';

export interface Props {
    mobile?: ReactNode | number[];
    desktop?: ReactNode | number[];
}

export const ResponsiveToggle: FC<Props> = ({ mobile, desktop }) => {
    const isResponsiveMode = useResponsive({ direction: MediaQueryDirection.below, size: MediaQuery.l });

    if (isResponsiveMode) {
        return !isEmpty(mobile) ? <>{mobile}</> : null;
    } else {
        return !isEmpty(desktop) ? <>{desktop}</> : null;
    }
};
