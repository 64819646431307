import { FC, useCallback, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { ItemsPerPage } from '@common/types';
import { ResponsiveToggle } from '@web/atoms/ResponsiveToggle';
import { fetchHomepageNews } from '@web/handlers/fetchHomepageNews';
import { Ad, AdPlacement } from '@web/molecules/Ad';
import { CarouselLayout, FeaturedCarrousel } from '@web/molecules/FeaturedCarrousel';
import { NotificationBarHolder } from '@web/molecules/NotificationBar';
import { MetaHead } from '@web/organisms/MetaHead';
import { Sidebar } from '@web/organisms/Sidebar';
import { WidgetWithFetch as RecentNewsWrapper } from '@web/organisms/SideWidgets/RecentNews';
import styles from '@web/templates/HomePage/HomePage.module.scss';
import { HomeNewsList } from '@web/templates/HomePage/organisms/HomeNewsList/HomeNewsList';

import { Props, State } from './HomePage';

export const ViHomePage: FC<Props> = ({
    contextData,
    state: initialState,
    featuredItems,
    headlinesDossier,
    oddsDossier,
}) => {
    const { platform, sidebarProps } = contextData;
    const [state, setState] = useState<State>(initialState);
    const { currentPage, news, hasNextPage } = state;

    const __meta = useTranslation('meta').t;

    const onReadMoreClick = useCallback(async () => {
        const newState = {
            ...state,
            currentPage: currentPage + 1,
        };
        const news = await fetchHomepageNews(contextData, newState.currentPage, true, ItemsPerPage.HUGE);
        newState.news = [...state.news, ...(news?.data || [])];
        newState.hasNextPage = news?.pagination?.hasNextPage || false;
        setState(newState);
    }, [state, currentPage, contextData, setState]);

    const recentNewsData = sidebarProps && 'recent-news' in sidebarProps ? sidebarProps['recent-news'] : [];
    const fiveRecentArticles = recentNewsData ? recentNewsData.filter((_, index) => index < 5) : [];

    return (
        <div className={styles.HomePage}>
            <MetaHead
                title={__meta(`${platform.id}-homepage.title`)}
                description={__meta(`${platform.id}-homepage.description`)}
            />
            <Ad placement={AdPlacement.HOME_BEFORE_CONTENT} />
            <Sidebar left />
            <div className="main-content">
                <FeaturedCarrousel
                    items={featuredItems}
                    isEditable={false}
                    desktopLayout={CarouselLayout.TWIN_MAINS}
                />
                <Ad placement={AdPlacement.HOME_AFTER_CAROUSEL} />
                <ResponsiveToggle mobile={<RecentNewsWrapper dataList={fiveRecentArticles} />} />
                <HomeNewsList
                    news={[...news].reverse()}
                    platform={platform.id}
                    hasNextPage={hasNextPage}
                    onReadMoreClick={onReadMoreClick}
                    headlinesDossier={headlinesDossier}
                    oddsDossier={oddsDossier}
                />
            </div>
            <NotificationBarHolder />
        </div>
    );
};
